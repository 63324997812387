import {
  type GetOpenShiftsRequestQuery,
  getOpenShiftsRequestQuery,
  getOpenShiftsResponse,
} from "@clipboard-health/contract-worker-app-bff";
import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type InfiniteData, type QueryClient, type UseQueryResult } from "@tanstack/react-query";
import { minutesToMilliseconds } from "date-fns";

import type { GetOpenShiftsResponse } from "./types";

export {
  type GetOpenShiftsRequestQuery,
  getOpenShiftsRequestQuery,
  type GetOpenShiftsResponse,
  getOpenShiftsResponse,
} from "@clipboard-health/contract-worker-app-bff";

export const GET_OPEN_SHIFTS_URL = `${environmentConfig.REACT_APP_WORKER_APP_BFF_URL}/open-shifts`;

export async function invalidateGetOpenShifts(queryClient: QueryClient): Promise<void> {
  // We don't want to fetch all pages of shifts on refresh, just the first one.
  queryClient.setQueriesData<InfiniteData<GetOpenShiftsResponse>>(
    ["paginated-query", GET_OPEN_SHIFTS_URL],
    (data) => {
      if (data && data.pages.length > 1) {
        return {
          pages: [data.pages[0]],
          pageParams: [data.pageParams[0]],
        };
      }

      return data;
    }
  );
  await queryClient.invalidateQueries({ queryKey: ["paginated-query", GET_OPEN_SHIFTS_URL] });
  await queryClient.invalidateQueries({ queryKey: [GET_OPEN_SHIFTS_URL] });
}

export function useGetOpenShifts(
  query: GetOpenShiftsRequestQuery,
  options: UseGetQueryOptions<GetOpenShiftsResponse> = {}
): UseQueryResult<GetOpenShiftsResponse> {
  return useGetQuery<GetOpenShiftsRequestQuery, GetOpenShiftsResponse>({
    url: GET_OPEN_SHIFTS_URL,
    queryParams: query,
    requestSchema: getOpenShiftsRequestQuery,
    responseSchema: getOpenShiftsResponse,
    meta: {
      userErrorMessage: "Something went wrong while loading shifts",
      logErrorMessage: APP_V2_APP_EVENTS.GET_LIST_VIEW_OPEN_SHIFTS_FAILURE,
    },
    staleTime: minutesToMilliseconds(1),
    ...options,
  });
}
