import { isDefined } from "@clipboard-health/util-ts";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { isOfferValidForShift } from "./isOfferValidForShift";
import { getOfferCacheKey, useCachedOffer, useCreateOffer } from "./useCreateOffer";

interface AutoRefreshOfferOptions {
  enabled?: boolean;
}

/**
 * Automatically refreshes an offer for a shift if it has expired.
 *
 * This is useful for shifts that are displayed in a list, where the offer may expire
 * after the shift is displayed.
 */
export function useAutoRefreshOffer(
  params: { shiftId?: string; onCallShiftId?: string },
  options: AutoRefreshOfferOptions = {}
) {
  const { shiftId, onCallShiftId } = params;
  const { enabled = true } = options;

  const queryClient = useQueryClient();

  const { mutate: createOffer, isError, isLoading } = useCreateOffer(params);
  const offer = useCachedOffer(shiftId, onCallShiftId);

  const shouldCreateOffer =
    isDefined(shiftId) && enabled && !isOfferValidForShift({ shiftId, offer });

  useEffect(() => {
    if (isError) {
      // Can't create an offer, so don't try again
      return;
    }

    if (isDefined(shiftId) && shouldCreateOffer) {
      const cacheKey = getOfferCacheKey(shiftId, onCallShiftId);
      const hasPendingMutation = queryClient.getMutationCache().find({
        exact: false,
        fetching: true,
        mutationKey: cacheKey,
      });

      if (!hasPendingMutation) {
        createOffer({ shiftId, onCallShiftId });
      }
    }
  }, [shiftId, onCallShiftId, shouldCreateOffer, queryClient, createOffer, isError]);

  return {
    data: { offer },
    isLoading,
    isError,
  };
}
