import { getShiftModalResponse } from "@clipboard-health/contract-worker-app-bff";
import { isDefined } from "@clipboard-health/util-ts";
import { useGetQuery } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import axios from "axios";
import { minutesToMilliseconds } from "date-fns";

import { mapShiftModalData } from "../convertModalShiftData";
import { convertOfferToModalShiftOfferData } from "../convertOpenShiftToModalShiftData";
import type { ModalShiftData } from "../types";
import { useAutoRefreshOffer } from "./useAutoRefreshOffer";

interface UseGetShiftModalOptions {
  enabled?: boolean;
  autoRefreshOffer?: boolean;
}

export const GET_SHIFT_MODAL_URL = `${environmentConfig.REACT_APP_WORKER_APP_BFF_URL}/shift-modals/`;

/**
 * Fetches shift modal details and converts them to the modal shift data format.
 * Also fetches an auto-refreshed offer for the shift, if enabled.
 */
export function useGetShiftModal(
  shiftId?: string,
  onCallShiftId?: string,
  options: UseGetShiftModalOptions = {}
) {
  const { enabled = true, autoRefreshOffer = true } = options;

  const {
    data: shiftModal,
    isLoading: shiftModalIsLoading,
    isError: shiftModalIsError,
  } = useGetQuery({
    url: GET_SHIFT_MODAL_URL + shiftId,
    responseSchema: getShiftModalResponse,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_OPEN_SHIFT_MODAL_FAILURE,
    },
    enabled: isDefined(shiftId) && enabled && !isDefined(onCallShiftId),
    staleTime: minutesToMilliseconds(1),
    // 404 is a legitimate error, so we don't want to show the error boundary in this case
    useErrorBoundary: (error) => {
      return !(axios.isAxiosError(error) && error.response?.status === 404);
    },
    // We don't want to retry the query if it's a 404
    retry: (_failureCount, error) => {
      return !(axios.isAxiosError(error) && error.response?.status === 404);
    },
  });

  const {
    data: { offer },
    isLoading: offerIsLoading,
    isError: offerIsError,
  } = useAutoRefreshOffer({ shiftId, onCallShiftId }, { enabled: autoRefreshOffer && enabled });

  let shift: ModalShiftData | undefined;
  if (isDefined(shiftModal)) {
    shift = mapShiftModalData({ shiftModal: shiftModal.data, offer });
  }

  if (isDefined(shift) && isDefined(offer)) {
    shift = {
      ...shift,
      offer: convertOfferToModalShiftOfferData(shift.attributes.durationInHours, offer),
    };
  }

  const shiftFailedLoading = isDefined(shiftId) && shiftModalIsError;

  return {
    data: { shift, offer },
    isLoading: shiftModalIsLoading,
    isError: shiftFailedLoading,
    offerIsError,
    offerIsLoading,
  };
}
