import { ShiftDocumentsPill } from "../Bookability/Documents/Pill";
import { ShiftType } from "../constants";
import { PriorityShiftPill } from "../Priority/Pill";
import { PriorityPlusShiftPill } from "../Priority/PlusPill";
import { UrgentShiftPill } from "../Urgent/Pill";

interface OpenShiftCardPillsProps {
  shiftType: ShiftType;
  missingDocumentsCount?: number;
  showMissingDocumentsCount?: boolean;
}

/**
 * Renders pills related to the open shift
 * - Urgent
 * - Priority
 * - Priority Plus (A-Team)
 * - Missing documents
 *
 * Wrap them with ShiftCardPillsWrapper to have a consistent look
 */
export function OpenShiftCardPills(props: OpenShiftCardPillsProps) {
  const { shiftType, missingDocumentsCount, showMissingDocumentsCount } = props;

  const showUrgentPill = shiftType === ShiftType.URGENT;

  const showPriorityPill =
    shiftType === ShiftType.PRIORITY || shiftType === ShiftType.PRIORITY_LEGACY;

  const showPriorityPlusPill = shiftType === ShiftType.PRIORITY_PLUS;

  const pillsCount = [
    showUrgentPill,
    showPriorityPill,
    showPriorityPlusPill,
    showMissingDocumentsCount,
  ].filter(Boolean).length;

  const hasMultiplePills = pillsCount > 1;

  if (!pillsCount) {
    return null;
  }

  return (
    <>
      {showUrgentPill && <UrgentShiftPill compact={hasMultiplePills} />}

      {showPriorityPill && <PriorityShiftPill />}

      {showPriorityPlusPill && <PriorityPlusShiftPill />}

      {showMissingDocumentsCount && (
        <ShiftDocumentsPill
          missingDocumentsCount={missingDocumentsCount ?? 0}
          compact={hasMultiplePills}
        />
      )}
    </>
  );
}
