import { Text, type TextProps } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box } from "@mui/material";

interface WorkplaceNameWithDistanceProps {
  workplaceName: string;
  formattedDistance?: string;
  textVariant?: TextProps["variant"];
  distanceTextVariant?: TextProps["variant"];
}

/**
 * Renders a workplace name with a map pin icon.
 */
export function WorkplaceNameWithDistance(props: WorkplaceNameWithDistanceProps) {
  const {
    workplaceName,
    textVariant = "body1",
    distanceTextVariant = "body2",
    formattedDistance,
  } = props;

  return (
    <Text
      variant={textVariant}
      sx={(theme) => ({
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: theme.palette.text.secondary,
      })}
    >
      {workplaceName}

      {isDefined(formattedDistance) && (
        <Box
          component="span"
          sx={(theme) => ({
            paddingLeft: 3,
            color: theme.palette.text.tertiary,
            fontSize: theme.typography[distanceTextVariant].fontSize,
            lineHeight: theme.typography[distanceTextVariant].lineHeight,
          })}
        >
          {formattedDistance}
        </Box>
      )}
    </Text>
  );
}
