import { API_TYPES, type OpenShiftWorkplace, type RateNegotiation } from "./types";
import { type GetOpenShiftsResponse } from "./useGetOpenShifts";

export function getIncludedOpenShiftsData(data?: GetOpenShiftsResponse) {
  const workplacesMap = new Map<string, OpenShiftWorkplace>(
    ((data?.included ?? []) as OpenShiftWorkplace[])
      .filter((entity) => entity.type === API_TYPES.workplace)
      .map((workplace) => [workplace.id, workplace])
  );

  const rateNegotiationsMap = new Map<string, RateNegotiation>(
    ((data?.included ?? []) as RateNegotiation[])
      .filter((entity) => entity.type === API_TYPES.rateNegotiation)
      .map((rateNegotiation) => [rateNegotiation.id, rateNegotiation])
  );

  return {
    workplacesMap,
    rateNegotiationsMap,
  };
}
