import { createOfferResponse, type OpenShift } from "@clipboard-health/contract-worker-app-bff";
import { type z } from "zod";

export {
  API_TYPES,
  type GetOpenShiftsResponse,
  type Offer,
  type OpenShift,
  // Alias to avoid conflicts with other Workplace types
  type Workplace as OpenShiftWorkplace,
  type RateNegotiation,
  TimeSlot,
  Urgency,
  Window,
} from "@clipboard-health/contract-worker-app-bff";

export type OpenShiftListItem = OpenShift;

export const shiftOfferSchema = createOfferResponse.shape.data;
export type ShiftOffer = z.infer<typeof shiftOfferSchema>;
