import {
  type CreateOfferResponse,
  createOfferResponse,
  createOfferSchema,
} from "@clipboard-health/contract-worker-app-bff";
import { isDefined } from "@clipboard-health/util-ts";
import { post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import {
  type QueryClient,
  useMutation,
  type UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { parseISO } from "date-fns";

import { isOfferValidForShift } from "./isOfferValidForShift";

export const CREATE_OFFER_URL = `${environmentConfig.REACT_APP_WORKER_APP_BFF_URL}/offers`;

interface UseCreateOfferParams {
  shiftId: string;
  onCallShiftId?: string;
}

export function getOfferCacheKey(shiftId: string, onCallShiftId?: string) {
  return ["offer", shiftId, ...(isDefined(onCallShiftId) ? [onCallShiftId] : [])];
}

export function useCachedOffer(shiftId?: string, onCallShiftId?: string) {
  const queryClient = useQueryClient();
  if (!isDefined(shiftId)) {
    return undefined;
  }

  return queryClient.getQueryData<CreateOfferResponse>(getOfferCacheKey(shiftId, onCallShiftId))
    ?.data;
}

/**
 * Caches the offer in the query client based on the offer's expiration time so we're
 * not constantly making requests while the user scrolls up and down.
 */
function cacheOffer(
  queryClient: QueryClient,
  params: { shiftId: string; onCallShiftId?: string; offer: CreateOfferResponse }
) {
  const { shiftId, onCallShiftId, offer } = params;
  const expiresAtToMs = parseISO(offer.data.attributes.refreshAt).getTime();
  const timeRemaining = expiresAtToMs - Date.now();

  queryClient.setQueryData(getOfferCacheKey(shiftId, onCallShiftId), () => offer);
  queryClient.setQueryDefaults(getOfferCacheKey(shiftId, onCallShiftId), {
    staleTime: timeRemaining,
  });
}

export function useCreateOffer(
  params: Partial<UseCreateOfferParams>,
  options: UseMutationOptions<
    CreateOfferResponse,
    AxiosError<CreateOfferResponse>,
    UseCreateOfferParams
  > = {}
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: getOfferCacheKey(params.shiftId ?? "", params.onCallShiftId ?? ""),
    retry: (failureCount, error) => {
      // Don't retry if forbidden, means we can't create an offer
      if (error.response?.status === 403) {
        return false;
      }

      return failureCount < 3;
    },
    retryDelay: 300,
    mutationFn: async (data) => {
      const existingOffer = queryClient.getQueryData<CreateOfferResponse>(
        getOfferCacheKey(data.shiftId, data.onCallShiftId)
      );
      if (
        isDefined(existingOffer) &&
        isOfferValidForShift({ shiftId: data.shiftId, offer: existingOffer.data })
      ) {
        return existingOffer;
      }

      const response = await post({
        url: CREATE_OFFER_URL,
        data: {
          data: {
            type: "offer",
            attributes: {},
            relationships: {
              shift: {
                data: {
                  type: "open-shift",
                  id: data.shiftId,
                },
              },
              ...(isDefined(data.onCallShiftId)
                ? {
                    onCallShift: {
                      data: {
                        type: "on-call-shift",
                        id: data.onCallShiftId,
                      },
                    },
                  }
                : {}),
            },
          },
        },
        requestSchema: createOfferSchema,
        responseSchema: createOfferResponse,
      });

      logEvent(APP_V2_USER_EVENTS.OFFER_CREATED, {
        shiftId: data.shiftId,
        onCallShiftId: data.onCallShiftId,
        offerId: response.data.data.id,
        data: response.data.data,
      });

      return response.data;
    },
    onSuccess: (newOffer, params) => {
      cacheOffer(queryClient, {
        shiftId: params.shiftId,
        onCallShiftId: params.onCallShiftId,
        offer: newOffer,
      });
    },
    ...options,
  });
}
