import { get } from "@src/appV2/api/api";
import { APP_V2_APP_EVENTS, type DateRange } from "@src/appV2/lib";
import {
  type QueryFunctionContext,
  type QueryKey,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
} from "@tanstack/react-query";
import { addDays, differenceInDays, endOfDay, minutesToMilliseconds, startOfDay } from "date-fns";

import {
  GET_OPEN_SHIFTS_URL,
  type GetOpenShiftsRequestQuery,
  getOpenShiftsRequestQuery,
  type GetOpenShiftsResponse,
  getOpenShiftsResponse,
} from "./useGetOpenShifts";

interface GetPaginatedOpenShiftsParams extends Omit<GetOpenShiftsRequestQuery, "filter"> {
  initialDateRange: DateRange;
  filter: Omit<GetOpenShiftsRequestQuery["filter"], "start">;
}

interface GetPaginatedOpenShiftsResponse extends GetOpenShiftsResponse {
  dateRange: DateRange;
}

/**
 * Calculates the next date range based on the current date range.
 *
 * Example:
 *
 * If the current date range is 2024-01-01 to 2024-01-05,
 * the next date range will be 2024-01-06 to 2024-01-10.
 */
function getNextDateRange(dateRange: DateRange) {
  const daysToAdd = differenceInDays(dateRange.endDate, dateRange.startDate) + 1;
  return {
    startDate: startOfDay(addDays(dateRange.startDate, daysToAdd)),
    endDate: endOfDay(addDays(dateRange.endDate, daysToAdd)),
  };
}

/**
 * Paginates open shifts by date range.
 *
 * First, it fetches shifts for the given initial date range.
 * When fetchNextPage is called, it will generate the next date range
 * and fetch open shifts for it.
 *
 * The next date range is calculated based on the number of days between
 * the start and end date of the initial date range.
 *
 * It determines that there are no more shifts to fetch if the last result
 * has no shifts. We might want to reconsider this in the future, as it
 * might be possible that a workplace has shifts far in the future that we
 * don't know about.
 */
export function useGetPaginatedOpenShifts(
  params: GetPaginatedOpenShiftsParams,
  options: UseInfiniteQueryOptions<GetPaginatedOpenShiftsResponse> = {}
) {
  return useInfiniteQuery({
    queryKey: ["paginated-query", GET_OPEN_SHIFTS_URL, params.filter],
    queryFn: async ({ pageParam: nextDateRange }: QueryFunctionContext<QueryKey, DateRange>) => {
      const dateRange = nextDateRange ?? params.initialDateRange;

      const response = await get({
        url: GET_OPEN_SHIFTS_URL,
        queryParams: {
          filter: {
            ...params.filter,
            start: {
              gte: dateRange.startDate.toISOString(),
              lte: dateRange.endDate.toISOString(),
            },
          },
          include: params.include,
        },
        requestSchema: getOpenShiftsRequestQuery,
        responseSchema: getOpenShiftsResponse,
      });

      return { ...response.data, dateRange };
    },
    meta: {
      userErrorMessage: "Something went wrong while loading shifts",
      logErrorMessage: APP_V2_APP_EVENTS.GET_LIST_VIEW_OPEN_SHIFTS_FAILURE,
    },
    getNextPageParam: (lastPage) => {
      return lastPage.data.length > 0 ? getNextDateRange(lastPage.dateRange) : undefined;
    },
    // We don't want to refetch open shifts frequently as it can cause bad UX and performance issues.
    staleTime: minutesToMilliseconds(1),
    ...options,
  });
}
