import { type Offer } from "@clipboard-health/contract-worker-app-bff";
import { isDefined } from "@clipboard-health/util-ts";
import { isFuture, parseISO } from "date-fns";

interface IsOfferValidForShiftProps {
  shiftId: string;
  offer?: Offer;
}

export function isOfferValidForShift(props: IsOfferValidForShiftProps) {
  const { shiftId, offer } = props;

  return (
    isDefined(offer) &&
    isFuture(parseISO(offer.attributes.expiresAt)) &&
    offer.relationships?.shift.data.id === shiftId
  );
}
