import type { areaFilter } from "@clipboard-health/contract-worker-app-bff";
import { get } from "@src/appV2/api/api";
import { APP_V2_APP_EVENTS, type DateRange } from "@src/appV2/lib";
import {
  type InfiniteData,
  type QueryClient,
  type QueryFunctionContext,
  type QueryKey,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
} from "@tanstack/react-query";
import { endOfDay, minutesToMilliseconds, startOfDay } from "date-fns";
import { type z } from "zod";

import {
  GET_OPEN_SHIFTS_URL,
  type GetOpenShiftsRequestQuery,
  getOpenShiftsRequestQuery,
  type GetOpenShiftsResponse,
  getOpenShiftsResponse,
} from "./useGetOpenShifts";

type AreaFilter = z.infer<typeof areaFilter>;

interface GetPaginatedOpenShiftsByAreaParams extends Omit<GetOpenShiftsRequestQuery, "filter"> {
  initialArea: AreaFilter;
  dates: DateRange | Date[];
}

export interface GetPaginatedOpenShiftsByAreaResponse extends GetOpenShiftsResponse {
  area: AreaFilter;
}

export async function invalidateGetPaginatedOpenShiftsByArea(
  queryClient: QueryClient
): Promise<void> {
  // We don't want to fetch all pages of shifts on refresh, just the first one.
  queryClient.setQueriesData<InfiniteData<GetPaginatedOpenShiftsByAreaResponse>>(
    ["paginated-by-area-query", GET_OPEN_SHIFTS_URL],
    (data) => {
      if (data && data.pages.length > 1) {
        return {
          pages: [data.pages[0]],
          pageParams: [data.pageParams[0]],
        };
      }

      return data;
    }
  );
  await queryClient.invalidateQueries({
    queryKey: ["paginated-by-area-query", GET_OPEN_SHIFTS_URL],
  });
}

/**
 * Paginates open shifts by area.
 *
 * First, it fetches shifts for the given initial area.
 * When fetchNextPage is called, it will receive the next area
 * and fetch open shifts for it.
 */
export function useGetPaginatedOpenShiftsByArea(
  params: GetPaginatedOpenShiftsByAreaParams,
  options: UseInfiniteQueryOptions<GetPaginatedOpenShiftsByAreaResponse> = {}
) {
  return useInfiniteQuery({
    queryKey: ["paginated-by-area-query", GET_OPEN_SHIFTS_URL],
    queryFn: async ({ pageParam: nextArea }: QueryFunctionContext<QueryKey, AreaFilter>) => {
      const { initialArea, dates, include } = params;

      const area = nextArea ?? initialArea;
      const start = Array.isArray(dates)
        ? dates.map((date) => ({
            gte: startOfDay(date).toISOString(),
            lte: endOfDay(date).toISOString(),
          }))
        : {
            gte: dates.startDate.toISOString(),
            lte: dates.endDate.toISOString(),
          };

      const response = await get({
        url: GET_OPEN_SHIFTS_URL,
        queryParams: {
          filter: {
            area,
            start,
          },
          include,
        },
        requestSchema: getOpenShiftsRequestQuery,
        responseSchema: getOpenShiftsResponse,
      });

      return { ...response.data, area };
    },
    meta: {
      userErrorMessage: "Something went wrong while loading shifts",
      logErrorMessage: APP_V2_APP_EVENTS.GET_LIST_VIEW_OPEN_SHIFTS_FAILURE,
    },
    // Initial area is used here just to signal to the query that it can fetch more open shifts.
    getNextPageParam: () => params.initialArea,
    // We don't want to refetch open shifts frequently as it can cause bad UX and performance issues.
    staleTime: minutesToMilliseconds(1),
    ...options,
  });
}
